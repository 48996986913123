import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bulletins-group',
  templateUrl: './bulletins-group.component.html',
  styleUrls: ['./bulletins-group.component.scss'],
})
export class BulletinsGroupComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
