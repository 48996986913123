import {Component, OnDestroy, OnInit} from '@angular/core';
import {ColorSchemeService, ThemeEnum} from '../../../services/colorscheme.service';
import {Subscription} from 'rxjs';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';

@Component({
    selector: 'app-theme-toggler',
    templateUrl: './theme-toggler.component.html',
    styleUrls: ['./theme-toggler.component.scss'],
})
export class ThemeTogglerComponent implements OnInit, OnDestroy {
    isDarkTheme: boolean;
    private subscription: Subscription;

    constructor(
        private colorSchemeService: ColorSchemeService
    ) {
    }

    ngOnInit(): void {
        // Subscribe to the service to stay synchronized
        this.subscription = this.colorSchemeService.colorScheme$.subscribe((scheme) => {
            this.isDarkTheme = scheme === ThemeEnum.DARK;
        });
    }

    onSchemeChange(e: MatSlideToggleChange): void {
        this.colorSchemeService.setColorScheme(e.checked ? ThemeEnum.DARK : ThemeEnum.LIGHT);
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
}
