import {Component, Input, OnInit} from '@angular/core';
import {IFormStep, MD_COLS_2} from '../../../interfaces/IFormStep';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {InputTypeEnum} from '../../../enums/InputTypeEnum';
import {FileTypeEnum} from '../../../enums/FileTypeEnum';
import {ModularValidatorService} from '../../../services/modular-validator.service';

@Component({
    selector: 'app-file-form',
    templateUrl: './file-form.component.html',
    styleUrls: ['./file-form.component.scss'],
})
export class FileFormComponent implements OnInit {
    @Input() title = 'ajouter_document';
    @Input() multiple = true;
    @Input() acceptedFileTypes: FileTypeEnum[] = [FileTypeEnum.IMAGE];

    fileControl = new FormArray([], [Validators.compose([
        Validators.required,
        this.validatorService.fileTypeValidator(this.acceptedFileTypes ),
    ])]);

    form = new FormGroup({
        legend: new FormControl('', [Validators.required, Validators.minLength(3)]),
    });


    constructor(
        private validatorService: ModularValidatorService,
    ) {
    }

    handleSubmit(e: Event) {
        console.log('submit');
        console.log(this.fileControl);
        console.log(this.form.get('legend').value);
    }


    ngOnInit() {
    }

}
