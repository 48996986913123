import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Id} from '../../../interfaces/Id';
import {ISujetPhoto} from '../../../interfaces/ISujetPhoto';
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'app-sujet-galerie-carousel',
    templateUrl: './sujet-galerie-carousel.component.html',
    styleUrls: ['./sujet-galerie-carousel.component.scss'],
})
export class SujetGalerieCarouselComponent implements OnInit {
    @Input() subjectId: Id;
    @Input() photos: ISujetPhoto[] = [];
    @Input() selectedPhoto: ISujetPhoto;
    pathPrefix: string = environment.sujet_photo_url;

    @Output() selectedPhotoChange = new EventEmitter<ISujetPhoto>();

    constructor() {
    }

    ngOnInit() {
    }

    handleCarouselItemSelected(id: string) {
        this.selectedPhoto = this.photos.find(photo => photo.photo_path === id);
        this.selectedPhotoChange.emit(this.selectedPhoto);
    }

}
