import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-figure',
  templateUrl: './figure.component.html',
  styleUrls: ['./figure.component.scss'],
})
export class FigureComponent implements OnInit {
  @Input() imgSrc: string;
  @Input() imgAlt: string;
  @Input() blurhash?: string;
  @Input() height = '15rem';
  @Input() containerClass: string;

  constructor() { }

  ngOnInit() {
  }

}
