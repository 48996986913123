import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MapMarkerService {
  // Subject pour émettre l'ID ou l'index du marqueur à mettre en surbrillance
  private hoverSubject = new Subject<number>();

  // Observable que les composants peuvent écouter
  hover$ = this.hoverSubject.asObservable();

  // Fonction pour notifier qu'un élément est survolé
  hoverMarker(markerId: number) {
    this.hoverSubject.next(markerId);
  }
}