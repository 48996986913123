import {Directive, HostListener, Input, OnInit} from '@angular/core';


@Directive({
    selector: '[appScrollTo]',
})
export class ScrollToDirective implements OnInit {
    @Input('appScrollTo') targetId!: string;

    constructor() {
    }

    ngOnInit(): void {
        if (window.location.hash === `#${this.targetId}`) {
            // Timeout unfortunately necessary
            // Otherwise the scroll on page load "sometimes" doesn't work
            setTimeout(() => {
                document.querySelector(`#${this.targetId}`)?.scrollIntoView();
            }, 50);
        }
    }

    @HostListener('click') onClick(): void {
        // Adding Hash to current URL without updating the browser history
        const currentUrl = window.location.pathname;
        const newUrl = `${currentUrl.split('#')[0]}#${this.targetId}`;
        window.location.replace(newUrl);

        document.querySelector(`#${this.targetId}`)?.scrollIntoView();
    }

}
