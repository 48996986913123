import {Component, Input, OnInit} from '@angular/core';
import {ISujet} from '../../../interfaces/ISujet';
import { MapMarkerService } from '../../../map-marker.service';

@Component({
    selector: 'app-sujet-card',
    templateUrl: './sujet-card.component.html',
    styleUrls: ['./sujet-card.component.scss'],
})
export class SujetCardComponent implements OnInit {
    @Input() subject: ISujet;
    @Input() id: number;

    constructor(private mapMarkerService: MapMarkerService) {
    }

    ngOnInit() {
    }

    onHoverMarker(index: number) {
        // Notifier le service que l'élément est survolé
        this.mapMarkerService.hoverMarker(index);
      }

      onLeaveMarker() {
        // Indiquer que le survol est terminé (peut-être en envoyant un marqueur invalide, -1 par exemple)
        this.mapMarkerService.hoverMarker(-1);
      }

}
