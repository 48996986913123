import {Component, Input, OnInit} from '@angular/core';
import {Auth0Client} from '@auth0/auth0-spa-js';

import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilsService} from "../../services/utils.service";


@Component({
    selector: 'app-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
    isAuthenticated = false;
    @Input() params: { active: boolean | string, url: string };
    @Input() textTitle: string;
    previousUrl: string = null;

    private auth0Client: Auth0Client;

    constructor(
        private location: Location,
        private router: Router,
        private route: ActivatedRoute,
        private utils: UtilsService,
    ) {
    }

    async ngOnInit() {
        this.route.queryParamMap.subscribe(queryParams => {
            // Priority is given to "from" param, then params.url
            this.previousUrl = queryParams.get('from') ?? null;
        });
    }

    goBack() {
        this.location.back();
    }

    getPageTitleExcerpt() {
        if (this.textTitle) {
            return this.utils.getExcerpt(this.textTitle, 65);
        }
        return '';
    }

}
