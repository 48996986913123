import {Injectable, RendererFactory2} from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Color, ColorVariable, RGB} from './colors/color';
import {IonicColorKeyEnum} from '../enums/IonicColorKeyEnum';
import {Observable} from 'rxjs';

const COLOR_ATTR_MAP = {
    value: '',
    valueRgb: '-rgb',
    contrast: '-contrast',
    contrastRgb: '-contrast-rgb',
    shade: '-shade',
    tint: '-tint',
};

const DEFAULT_PRIMARY_COLOR = '#59bb78';

export enum ThemeEnum {
    LIGHT = 'light',
    DARK = 'dark',
}

@Injectable({
    providedIn: 'root'
})
export class ColorSchemeService {
    primaryColor$: BehaviorSubject<string>;

    // Local Storage key for theme preference
    private KEY = 'prefers-colors';

    constructor() {
        this.init();
    }

    private colorSchemeSubject = new BehaviorSubject<string>('light'); // Default value is 'light'
    colorScheme$: Observable<string> = this.colorSchemeSubject.asObservable();

    get currentScheme(): string {
        return this.colorSchemeSubject.value;
    }

    // Method to update the color scheme
    setColorScheme(scheme: string): void {
        this.colorSchemeSubject.next(scheme);
        this.onColorSchemeChange(scheme);
    }

    private onColorSchemeChange(scheme: string): void {
        document.body.className = ''; // Clear previous class
        document.body.classList.add(`${scheme}`); // Add new theme class to body

        // Save prefers-color-scheme to localStorage
        localStorage.setItem(this.KEY, scheme);
    }

    _detectPrefersColorScheme() {
        // Detect if prefers-color-scheme is supported
        if (window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
            // Set colorScheme to Dark if prefers-color-scheme is dark. Otherwise set to light.
            this.setColorScheme(window.matchMedia('(prefers-color-scheme: dark)')
                .matches ? ThemeEnum.DARK : ThemeEnum.LIGHT);
        } else {
            // If the browser doesn't support prefers-color-scheme, set it as default to dark
            this.setColorScheme(ThemeEnum.DARK);
        }
    }

    init() {
        // Check if any prefers-color-scheme is stored in localStorage
        if (localStorage.getItem(this.KEY)) {
            // Save prefers-color-scheme from localStorage
            this.setColorScheme(localStorage.getItem(this.KEY));
        } else {
            // If no prefers-color-scheme is stored in localStorage, try to detect OS default prefers-color-scheme
            this._detectPrefersColorScheme();
        }
    }

    updatePrimaryColor(color: string = DEFAULT_PRIMARY_COLOR) {
        this.primaryColor$.next(color);
        this.applyColor(
            IonicColorKeyEnum.PRIMARY,
            this.generateColor(color),
        );
    }

    generatePropertyName(colorName: string = IonicColorKeyEnum.PRIMARY): string {
        return `--ion-color-${colorName.toLowerCase()}`;
    }

    applyColor(colorName: string, colorValues: ColorVariable): void {
        Object.keys(COLOR_ATTR_MAP).forEach(k => {
            const baseName = this.generatePropertyName(colorName);
            console.log(baseName + COLOR_ATTR_MAP[k], colorValues[k]);
            document.documentElement.style.setProperty(
                baseName + COLOR_ATTR_MAP[k],
                colorValues[k],
            );
        });
    }

    generateColor(value: string): ColorVariable {
        const color = new Color(value);
        const contrast = color.contrast();
        const tint = color.tint();
        const shade = color.shade();

        return {
            value,
            valueRgb: this.rgbToString(color.rgb),
            contrast: contrast.hex,
            contrastRgb: this.rgbToString(contrast.rgb),
            tint: tint.hex,
            shade: shade.hex,
        };
    }

    rgbToString(c: RGB): string {
        return `${c.r},${c.g},${c.b}`;
    }

}
