import {Component, Input, OnInit} from '@angular/core';

export type DefinitionListItem = {
    term?: string;
    definition: string;
    color?: string;
    icon?: string;
};

@Component({
  selector: 'app-definition-list',
  templateUrl: './definition-list.component.html',
  styleUrls: ['./definition-list.component.scss'],
})
export class DefinitionListComponent implements OnInit {
  @Input() definitions: DefinitionListItem[];

  constructor() { }

  ngOnInit() {}

}
