import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-bulletin-card',
    templateUrl: './bulletin-card.component.html',
    styleUrls: ['./bulletin-card.component.scss'],
})
export class BulletinCardComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
