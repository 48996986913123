import {Component, OnInit} from '@angular/core';
import {EVService} from '../../../services/ev.service';
import {StorageService} from '../../../services/storage.service';
import {ColorSchemeService} from '../../../services/colorscheme.service';

export type Css = string;

// [Ionic Color Generator](https://ionicframework.com/docs/theming/color-generator)

@Component({
    selector: 'app-theme-fetcher',
    templateUrl: './theme-fetcher.component.html',
    styleUrls: ['./theme-fetcher.component.scss'],
})
export class ThemeFetcherComponent implements OnInit {
    css: Css = '';
    styleEl: HTMLStyleElement;
    readonly THEME_KEY = 'theme';

    constructor(
        // private ev: EVService,
        private storage: StorageService,
        private colorService: ColorSchemeService,
    ) {
    }

    ngOnInit() {
        // const head = document.querySelector('head');
        // this.styleEl = document.createElement('style');
        // this.styleEl.type = 'text/css';
        // head.appendChild(this.styleEl);
        // this.fetchTheme();
        // this.applyTheme();

        // this.colorService.primaryColor$.subscribe(color => {
        //     console.log(color);
        //     // this.css = `:root{--ion-color-primary: ${color};}`;
        //     // this.applyTheme();
        //     console.log('applying theme', color);
        //     // document.documentElement.style.setProperty('--ion-color-primary', color);
        // });
    }

    applyTheme(): void {
        this.styleEl.innerText = this.css;
    }

    fetchTheme(): void {
        this.css = this.storage.get<Css>(this.THEME_KEY) || '';
        // TODO remove this (For testing only)
        // this.css = ':root{--ion-color-primary: steelblue;}';
    }

}
