import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-projet-documents-section',
  templateUrl: './projet-documents-section.component.html',
  styleUrls: ['./projet-documents-section.component.scss'],
})
export class ProjetDocumentsSectionComponent implements OnInit {
  @Input() fichiers: {
    filename: string,
    url: string,
    hash: string,
    auteur: string,
    date_document: {
      date: string,

    }
  }[] = [];

  constructor() { }

  ngOnInit() {}

}
