import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {IonicColorKeyEnum} from '../../../enums/IonicColorKeyEnum';
import {NavController} from '@ionic/angular';

export type Anchor = {
    label: string,
    icon?: string,
    color: IonicColorKeyEnum | string
    target: string,
};

@Component({
    selector: 'app-anchor-layout',
    templateUrl: './anchor-layout.component.html',
    styleUrls: ['./anchor-layout.component.scss'],
})
export class AnchorLayoutComponent implements OnInit {
    @ViewChild('main') mainRef: ElementRef;

    @Input() anchors: Anchor[] = [];
    @Input() autoGenerateAnchors = true;

    private _loading = false;
    @Input()
    set loading(isLoading: boolean) {
        this._loading = isLoading;
        this.generateAnchors();
    }

    get isLoading() {
        return this._loading;
    }

    constructor(
        private navController: NavController,
    ) {
    }

    ngOnInit() {
        this.generateAnchors();
    }

    back() {
        this.navController.back();
    }

    /**
     * Generate anchors
     * Based on data-anchor-label and other attributes
     */
    generateAnchors() {
        if (this.isLoading || !this.autoGenerateAnchors) {
            return;
        }
        // Waiting for elements to render before generating anchors
        setTimeout(() => {
            const anchorsElements = this.mainRef.nativeElement.querySelectorAll('[data-anchor-label]');

            this.anchors = Array.from(anchorsElements).map((anchor: HTMLElement) => {
                return {
                    label: anchor.getAttribute('data-anchor-label'),
                    icon: anchor.getAttribute('data-anchor-icon'),
                    color: anchor.getAttribute('data-anchor-color'),
                    target: anchor.id,
                };
            });

        }, 200);
    }


}
