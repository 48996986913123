import {Component, Input, OnInit} from '@angular/core';
import {IonicColorKeyEnum} from '../../../enums/IonicColorKeyEnum';

export type BreadcrumbItem = {
    label: string;
    routerLink?: string[];
    queryParams?: Record<string, string>;
    icon?: string;
    color?: string|IonicColorKeyEnum;
};

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
    @Input() items: BreadcrumbItem[] = [];

    constructor() {
    }

    ngOnInit() {
    }

}
