export enum InputTypeEnum {
    TEXT = 'text',
    TEXTAREA = 'textarea',
    EMAIL = 'email',
    PASSWORD = 'password',
    SEARCH = 'search',
    TEL = 'tel',
    URL = 'url',
    DATE = 'date',
    TIME = 'time',
    DATETIME = 'datetime-local',
    MONTH = 'month',
    WEEK = 'week',
    COLOR = 'color',
    CHECKBOX = 'checkbox',
    RADIO = 'radio',
    SELECT = 'select',
    NUMBER = 'number',
    HIDDEN = 'hidden',
    // RANGE = 'range', TODO not supported yet
    // Customs types
    FILE = 'file',
    GEO = 'geo',
    NOT = 'not',
    CHECKLIST = 'checklist',
    ACCORDEON = 'accordeon',
    STEP = 'step',
    MUNSELL = 'munsell',
    RADIO_VERTICAL = 'radio_vertical',
    TABLE = 'table',
}
