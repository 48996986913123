import {Component, Input, OnInit} from '@angular/core';
import {ISujetPhoto} from '../../../interfaces/ISujetPhoto';
import {environment} from "../../../../environments/environment";
import {FormControl} from "@angular/forms";

@Component({
    selector: 'app-sujet-galerie-section',
    templateUrl: './sujet-galerie-section.component.html',
    styleUrls: ['./sujet-galerie-section.component.scss'],
})
export class SujetGalerieSectionComponent implements OnInit {
    @Input() photos: ISujetPhoto[] = [];
    featuredPhoto: ISujetPhoto;
    prefix: string = environment.sujet_photo_url;
    legendControl = new FormControl();

    constructor() {
    }

    ngOnInit() {
        if (this.photos.length > 0) {
            // Selecting "favorite" photo if any, or simply first photo
            this.featuredPhoto = this.photos.find(photo => photo.favorite) ?? this.photos[0];
        }
    }

    handleSelectedPhotoChanged(photo: ISujetPhoto) {
        this.featuredPhoto = photo;
        this.legendControl.setValue(this.featuredPhoto.legend);
    }

}
