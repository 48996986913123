import {Component, Input, OnInit} from '@angular/core';
import {Indicateurs} from '../../../interfaces/Indicateurs';

@Component({
  selector: 'app-keyvals',
  templateUrl: './keyvals.component.html',
  styleUrls: ['./keyvals.component.scss'],
})
export class KeyvalsComponent implements OnInit {
  @Input() indicateurs: Indicateurs;

  constructor() {}

  ngOnInit() {
  }

}
