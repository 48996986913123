import {Component, OnDestroy, OnInit} from '@angular/core';
import {Language, LanguageService} from '../../../services/language.service';
import {Subscription} from 'rxjs';


@Component({
    selector: 'app-language-selector',
    templateUrl: './language-selector.component.html',
    styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {
    currentLanguage: string;
    private subscription: Subscription;
    constructor(
        private languageService: LanguageService,
    ) {
    }

    ngOnInit() {
        this.subscription = this.languageService.language$.subscribe(language => {
            this.currentLanguage = language;
        });
    }

    handleLanguageChange(language: string): void {
        this.languageService.setLanguage(language);
    }

    getAvailableLanguages(): Language[] {
        return this.languageService.LANGUAGES;
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

}
