import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-newsletter-form',
    templateUrl: './newsletter-form.component.html',
    styleUrls: ['./newsletter-form.component.scss'],
})
export class NewsletterFormComponent implements OnInit {
    hasAcceptedConditions = false;

    constructor() {
    }

    ngOnInit() {
    }

    handleHasAcceptedConditionsChanged() {

    }

}
