import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GenericLoaderService } from './generic-loader.service';

@Injectable()
export class GenericLoaderInterceptor implements HttpInterceptor {

  constructor(private loaderService: GenericLoaderService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.url;  // Récupère l'URL de la requête
   // console.log(`Interceptor activated for: ${url}`);
    this.loaderService.show(url);  // Passe l'URL au service pour l'afficher et la stocker

    return next.handle(req).pipe(
      finalize(() => this.loaderService.hide())  // Cache le loader à la fin de la requête
    );
  }
}