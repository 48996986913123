import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Observable} from 'rxjs';

export type Language = {
    key: string,
    name: string,
};

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    readonly LANGUAGES: Language[] = [
        {
            key: 'fr',
            name: 'Français',
        },
        {
            key: 'en',
            name: 'English',
        },
        {
            key: 'de',
            name: 'Deutsch',
        },
    ];

    private KEY = 'language';
    private languageSubject = new BehaviorSubject<string>('en');
    language$: Observable<string> = this.languageSubject.asObservable();


    constructor(
        private translate: TranslateService
    ) {
        translate.addLangs(this.LANGUAGES.map((language) => {
            return language.key;
        }));
        this.setLanguage(this.getPreferredLanguage());
    }

    get getLanguage(): string {
        return this.languageSubject.value;
    }

    setLanguage(language: string): void {
        this.languageSubject.next(language);
        localStorage.setItem(this.KEY, language);
        this.translate.use(language);
    }

    public getPreferredLanguage(): string {
        if (localStorage.getItem(this.KEY)) {
            return (localStorage.getItem(this.KEY));
        }
        const browserLang = this.translate.getBrowserLang();
        return (browserLang.match(/fr|de|es|pt|it|ar|en/) ? browserLang : 'en');
    }
}
