import { Component, Input, ElementRef, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-circular-photo-scroll',
  templateUrl: './circular-photo-scroll.component.html',
  styleUrls: ['./circular-photo-scroll.component.scss']
})
export class CircularPhotoScrollComponent implements OnInit {
  @Input() photos: string[] = [];

  isDown = false;
  startX = 0;
  scrollLeft = 0;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {}

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent): void {
    this.isDown = true;
    const slider = this.el.nativeElement.querySelector('.photo-container');
    this.startX = event.pageX - slider.offsetLeft;
    this.scrollLeft = slider.scrollLeft;
    event.preventDefault(); // Empêcher tout comportement par défaut
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.isDown = false;
  }

  @HostListener('mouseup')
  onMouseUp(): void {
    this.isDown = false;
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    if (!this.isDown) return;
    event.preventDefault();
    const slider = this.el.nativeElement.querySelector('.photo-container');
    const x = event.pageX - slider.offsetLeft;
    const walk = (x - this.startX) * 2; // Ajuster la vitesse de défilement
    slider.scrollLeft = this.scrollLeft - walk;
  }

  // Gérer le scroll à la molette pour faire défiler horizontalement
  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent): void {
    const slider = this.el.nativeElement.querySelector('.photo-container');
    slider.scrollLeft += event.deltaY; // Défilement horizontal à la molette
    event.preventDefault(); // Empêche le parent de scroller
  }
}