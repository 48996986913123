import { Component, OnInit, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-offline-banner',
  templateUrl: './offline-banner.component.html',
  styleUrls: ['./offline-banner.component.scss']
})
export class OfflineBannerComponent implements OnInit, OnDestroy {
  isOffline: boolean = !navigator.onLine;
  private onlineEvent!: Subscription;
  private offlineEvent!: Subscription;

  ngOnInit() {
    // Abonnez-vous aux événements en ligne et hors ligne
    this.onlineEvent = fromEvent(window, 'online').subscribe(() => {
      this.isOffline = false;
    });

    this.offlineEvent = fromEvent(window, 'offline').subscribe(() => {
      this.isOffline = true;
    });
  }

  ngOnDestroy() {
    // Désabonnez-vous pour éviter les fuites de mémoire
    this.onlineEvent.unsubscribe();
    this.offlineEvent.unsubscribe();
  }
}