import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-keyval',
    templateUrl: './keyval.component.html',
    styleUrls: ['./keyval.component.scss'],
})
export class KeyvalComponent implements OnInit {
    @Input() label: string;
    @Input() value: string | number;

    constructor() {
    }

    ngOnInit() {
    }

}
