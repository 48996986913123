import {Component, Input, OnInit} from '@angular/core';
import {IonicColorKeyEnum} from '../../../enums/IonicColorKeyEnum';

@Component({
    selector: 'app-card-row',
    templateUrl: './card-row.component.html',
    styleUrls: ['./card-row.component.scss'],
})
export class CardRowComponent implements OnInit {
    @Input() label: string;
    @Input() value: string|number;
    @Input() icon: string;
    @Input() color: string|IonicColorKeyEnum;

    constructor() {
    }

    ngOnInit() {
    }

}
