import {Component, Input, OnInit} from '@angular/core';
import {PdfDownloadService} from '../../../pdf-download.service';

@Component({
  selector: 'app-document-item',
  templateUrl: './document-item.component.html',
  styleUrls: ['./document-item.component.scss'],
})
export class DocumentItemComponent implements OnInit {
  @Input() author: string;
  @Input() fileName: string;
  @Input() date: string;
  @Input() path: string;

  constructor(
  ) { }

  ngOnInit() {}

  downloadFile() {
  }

}
