import {domain, clientId, audience, apiUri} from '../../auth_config.json';

export const environment = {
    production: false,
    enableDebug: true,
    version: '4.0.0',
    mapStyleUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    mixpanel_token: '5017fb4d1032395dacf774f41d228b61',
    api_url: 'https://arbre.urbasense.eu/Southside_api/api_refonte/',
    // sujet_photo_url: 'https://preprodarbre.urbasense.eu',
    sujet_photo_url: 'https://arbre.urbasense.eu',
    key_roles: 'http://urbasense/',
    auth: {
        "domain": "urbasense2k.eu.auth0.com",
        "clientId": "37BlrJ8K9OoTOhtVUoBlLYj6RThJ6nvp",
        "audience": "https://api-webapp-urbasense.com",
        "CacheLocation": "localstorage",
        redirectUri: window.location.origin,
    },
    httpInterceptor: {
        allowedList: ['https://arbre.urbasense.eu/Southside_api/api_refonte/private/*'],
    },
};


//37BlrJ8K9OoTOhtVUoBlLYj6RThJ6nvp prod
//4g22oqa2kSJ8G7QgPF8LzkGPy3p742Rs dev
