import {Component, Input, OnInit} from '@angular/core';
import {IonicColorKeyEnum} from '../../../enums/IonicColorKeyEnum';
import {PdfDownloadService} from '../../../pdf-download.service';

export enum ButtonVariantEnum {
    DEFAULT = 'default',
    OUTLINE = 'outline',
    GHOST = 'ghost',
    // SOFT = 'soft',
}

export enum ButtonSizeEnum {
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
}

export enum ButtonFill {
    INLINE = 'inline',
    BLOCK = 'block',
}

export enum ButtonRadius {
    NONE = 'none',
    ROUNDED = 'rounded',
    FULL = 'full',
}

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
    // Style
    @Input() color: IonicColorKeyEnum | string = IonicColorKeyEnum.PRIMARY;
    @Input() variant: ButtonVariantEnum | string = ButtonVariantEnum.DEFAULT;
    @Input() size: ButtonSizeEnum | string = ButtonSizeEnum.MD;
    @Input() fill: ButtonFill | string = ButtonFill.INLINE;
    @Input() rounded: ButtonRadius | string = ButtonRadius.ROUNDED;
    @Input() btnClass = '';
    @Input() icon: string = null;

    // Button attr
    @Input() type = 'button';
    @Input() role = 'button';
    @Input() disabled = false;
    @Input() downloadUrl: string;
    @Input() saveAs: string;


    // If button should be rendered as an anchor
    @Input() to: string | string[];
    @Input() queryParams: object = {};

    // For external links
    @Input() href: string;
    @Input() target: '_blank' | '_self' | '_parent' | '_top' = '_self';

    constructor(
        private downloadService: PdfDownloadService,
    ) {
    }

    public downloadFile() {
        if (null == this.downloadUrl) {
            return;
        }
        this.downloadService.downloadFile(this.downloadUrl, this.saveAs);
    }
}
