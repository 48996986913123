export enum FormGroupStatusEnum {
    VALID = 'VALID',
    INVALID = 'INVALID',
    PENDING = 'PENDING',
    DISABLED = 'DISABLED',
    ENABLED = 'ENABLED',
    DIRTY = 'DIRTY',
    PRISTINE = 'PRISTINE',
    TOUCHED = 'TOUCHED',
    UNTOUCHED = 'UNTOUCHED',
}
