import { Component, Input, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, Type } from '@angular/core';
import {Anchor} from '../anchor-layout/anchor-layout.component';
import {SujetDetailsSectionComponent} from '../../organisms/sujet-details-section/sujet-details-section.component';
import {
    SujetDispositifSectionComponent
} from '../../organisms/sujet-dispositif-section/sujet-dispositif-section.component';
import {SujetGalerieSectionComponent} from '../../organisms/sujet-galerie-section/sujet-galerie-section.component';
import {
    SujetLocalisationSectionComponent
} from '../../organisms/sujet-localisation-section/sujet-localisation-section.component';
import {SujetStadeSectionComponent} from '../../organisms/sujet-stade-section/sujet-stade-section.component';
import {SujetHelpSectionComponent} from '../../organisms/sujet-help-section/sujet-help-section.component';
import {SujetNotesSectionComponent} from '../../organisms/sujet-notes-section/sujet-notes-section.component';
import {
    SujetDernierBulletinSectionComponent
} from "../../organisms/sujet-dernier-bulletin-section/sujet-dernier-bulletin-section.component";
import {ProjetSettingsComponent} from "../../organisms/projet-settings/projet-settings.component";
import {
    ProjetDocumentsSectionComponent
} from "../../organisms/projet-documents-section/projet-documents-section.component";
import {ProjetGroupeSectionComponent} from "../../organisms/projet-groupe-section/projet-groupe-section.component";
import {
    ProjetContactsSectionComponent
} from "../../organisms/projet-contacts-section/projet-contacts-section.component";
import {
    ProjetCaracteristiquesComponent
} from "../../organisms/projet-caracteristiques/projet-caracteristiques.component";


export interface ModularSectionProps {
    meta?: Anchor;
    componentObject?: Type<any>;
    component?: string;
    props?: { [key: string]: any };
}

@Component({
    selector: 'app-modular-section',
    templateUrl: './modular-section.component.html',
})
export class ModularSectionComponent implements OnInit {
    @Input() data: ModularSectionProps;
    @Input() commonProps: object = {};

    @ViewChild('dynamicContainer', { read: ViewContainerRef, static: true })
    dynamicContainer!: ViewContainerRef;
    constructor(
        private resolver: ComponentFactoryResolver
    ) {}

    ngOnInit(): void {
        if (this.data) {
            if (this.data.component) {
                this.data.componentObject = this.componentFromString(this.data.component);
            }

            if (this.data.component) {
                this.loadComponent();
            } else {
                console.error('Can\'t find component ' + this.data.component);
            }
        }
    }

    private loadComponent(): void {
        const componentFactory = this.resolver.resolveComponentFactory(this.data.componentObject);
        this.dynamicContainer.clear();
        const componentRef = this.dynamicContainer.createComponent(componentFactory);
        if (this.data.props) {
            Object.assign(componentRef.instance, {...this.data.props, ...this.commonProps});
        }
    }

    private componentFromString(component: string): Type<any> {
        const map = {
            // Single Sujet
            'app-sujet-details-section': SujetDetailsSectionComponent,
            'app-sujet-dispositif-section': SujetDispositifSectionComponent,
            'app-sujet-bulletin-section': SujetDernierBulletinSectionComponent,
            'app-sujet-galerie-section': SujetGalerieSectionComponent,
            'app-sujet-localisation-section': SujetLocalisationSectionComponent,
            'app-sujet-stade-section': SujetStadeSectionComponent,
            'app-sujet-notes-section': SujetNotesSectionComponent,
            'app-sujet-help-section': SujetHelpSectionComponent,
            // Single Projet
            'app-projet-caracteristiques': ProjetCaracteristiquesComponent,
            'app-projet-settings': ProjetSettingsComponent,
            'app-projet-documents-section': ProjetDocumentsSectionComponent,
            'app-projet-groupe-section': ProjetGroupeSectionComponent,
            'app-projet-contacts-section': ProjetContactsSectionComponent,
        };

        return map[component];
    }
}
