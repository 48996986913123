import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor() {
    }

    set<T>(key: string, value: T): boolean {
        try {
            localStorage.setItem(key, JSON.stringify(value));
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    }

    getKey<T>(key: string, subKey: string): T {
        return this.getOrDefault(key)[subKey];
    }

    setKey<T>(key: string, subKey: string, value: T): boolean {
        const object = this.getOrDefault<T>(key);
        object[subKey] = value;
        return this.set<T>(key, object);
    }

    removeKey<T>(key: string, subKey: string): boolean {
        const object = this.getOrDefault<T>(key);
        delete object[subKey];
        return this.set<T>(key, object);
    }

    get<T>(key: string): T {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    }


    getOrDefault<T>(key: string): T {
        const value = this.get<T>(key);
        if (null !== value) {
            return value;
        }
        this.set(key, {}); // Setting a default value
        return this.get<T>(key);
    }

    remove(key: string): void {
        localStorage.removeItem(key);
    }

    clear(): void {
        localStorage.clear();
    }
}
