import {Component, Input, OnInit} from '@angular/core';
import {Sujet} from '../../../interfaces/ISujet';
import {Indicateurs} from '../../../interfaces/Indicateurs';

@Component({
  selector: 'app-sujet-details-section',
  templateUrl: './sujet-details-section.component.html',
  styleUrls: ['./sujet-details-section.component.scss'],
})
export class SujetDetailsSectionComponent implements OnInit {
  @Input() subject: Sujet;
  @Input() indicateur_stats: Indicateurs;
  @Input() indicateur_id: Indicateurs;

  constructor() { }

  ngOnInit() {}

}
