import {AsyncValidatorFn, FormGroup, ValidatorFn} from '@angular/forms';
import {InputTypeEnum} from '../enums/InputTypeEnum';
import {IPosition} from './IPosition';
import {MatVerticalStepper} from '@angular/material/stepper';
import {FileTypeEnum} from '../enums/FileTypeEnum';
import {ComponentType} from '@angular/cdk/overlay';

/**
 * Objects made to be injected as props in ModularForm component
 * @author Maxime Montagne
 */

export interface IFormStep {
    fields: ModularFormField[];
    label?: string;
    // labelHtml?: string // TODO should be implemented separetly of "label"
    formGroup?: FormGroup; // Generated in ModularForm onInit
    gridTemplate?: GridTemplateConfigs;
    onPrev?: StepTransitionFn;
    onNext?: StepTransitionFn;
    enablePrev?: boolean;
    onChange?: OnChangeFn;
}

export type StepTransitionFn = (
    stepper: MatVerticalStepper,
    formGroup?: FormGroup,
    steps?: IFormStep[]
) => void;

export type OnChangeFn = (
    newValue: InputValue | InputValue[],
    steps: IFormStep[],
    stepper: MatVerticalStepper,
    fieldKey: string
) => void;

export type InputValue = string | number | boolean | IPosition | FileValue;

export type GridTemplateConfig = {
    cols?: number;
    rows?: number;
    justifyContent?: GridPositionEnum;
    justifyItems?: GridPositionEnum;
    alignItems?: GridPositionEnum;
};

export type GridTemplateConfigs = {
    xs?: GridTemplateConfig;
    sm?: GridTemplateConfig;
    md?: GridTemplateConfig;
    lg?: GridTemplateConfig;
    xl?: GridTemplateConfig;
    xxl?: GridTemplateConfig;
};

export const MD_COLS_2: GridTemplateConfigs = {
    md: {
        cols: 2
    }
};

export const MD_COLS_3: GridTemplateConfigs = {
    md: {
        cols: 3
    }
};

export type GridElementConfig = {
    col?: number;
    row?: number;
    colspan?: number;
    rowspan?: number;
    justifySelf?: GridPositionEnum;
    alignSelf?: GridPositionEnum;
};

export type GridElementConfigs = {
    xs?: GridElementConfig;
    sm?: GridElementConfig;
    md?: GridElementConfig;
    lg?: GridElementConfig;
    xl?: GridElementConfig;
    xxl?: GridElementConfig;
};

export enum GridPositionEnum {
    STRETCH = 'stretch',
    CENTER = 'center',
    START = 'start',
    END = 'end',
    BASELINE = 'baseline',
}

export type RegularInputType =
    InputTypeEnum.TEXT
    | InputTypeEnum.TEXTAREA
    | InputTypeEnum.EMAIL
    | InputTypeEnum.DATE
    | InputTypeEnum.DATETIME
    | InputTypeEnum.HIDDEN
    | InputTypeEnum.WEEK
    | InputTypeEnum.URL
    | InputTypeEnum.COLOR
    | InputTypeEnum.MUNSELL
    | InputTypeEnum.NUMBER
    | InputTypeEnum.NOT // TODO
    | InputTypeEnum.STEP // TODO
    // CHOICES
    | InputTypeEnum.CHECKBOX
    | InputTypeEnum.SELECT
    | InputTypeEnum.RADIO
    | InputTypeEnum.RADIO_VERTICAL
    | InputTypeEnum.CHECKLIST
    ;

export type ChoiceInputType =
    InputTypeEnum.CHECKBOX
    | InputTypeEnum.SELECT
    | InputTypeEnum.RADIO
    | InputTypeEnum.RADIO_VERTICAL
    | InputTypeEnum.CHECKLIST
    ;

export type ModularFormField =
    IFormField
    // | IFormFieldChoice
    | IFormFieldFile
    | IFormFieldTable
    | IFormFieldAccordeon
    | IFormFieldGeo;

export interface AbstractFormField {
    key: string;
    type: InputTypeEnum;
    control?: [InputValue | InputValue[], ValidatorFn?, AsyncValidatorFn?];
    choices?: Choice[];
    label?: string;
    placeholder?: string;
    multiple?: boolean; // TODO extract this to relevant types
    required?: boolean;
    disabled?: boolean;
    help?: string;
    grid?: GridElementConfigs;
    widget?: InjectedWidget;
    scanner?: boolean;
    onChange?: OnChangeFn;
    onFormNav?: (stepper: MatVerticalStepper) => void;
    ignored?: boolean;
}

export interface IFormField extends AbstractFormField {
    type: RegularInputType;
}

// export interface IFormFieldChoice extends AbstractFormField {
//     type: ChoiceInputType;
//     choices: InputValue[]; // If type Select
// }

export interface IFormFieldGeo extends AbstractFormField {
    type: InputTypeEnum.GEO;
    routing?: boolean;
}

export interface IFormFieldFile extends AbstractFormField {
    type: InputTypeEnum.FILE;
    accept?: FileTypeEnum[];
    enableImageCompression?: boolean;
    imageCompressionSize?: Size;
}

export interface IFormFieldAccordeon extends AbstractFormField {
    type: InputTypeEnum.ACCORDEON;
    accordeonGroups: AccordeonInputGroup[];
}

export interface IFormFieldTable extends AbstractFormField {
    type: InputTypeEnum.TABLE;
    // choices: InputValue[]; // If type Select
    tableRows: string[];
    tableFields?: ModularFormField[];
    tableCommentKey?: string;
    tableCommentFields?: ModularFormField[];
}

export type AccordeonInputGroup = {
    title: string,
    description?: string,
    fields: ModularFormField[],
    disabled?: boolean,
    expanded?: boolean,
    gridTemplate?: GridTemplateConfigs;
};

export interface InjectedWidget {
    component?: ComponentType<any>;
    props?: object;
    title?: string;
    content?: string[];
    titleClass?: string;
    contentClass?: string;
}

export interface IModularFormData {
    [key: string]: InputValue | InputValue[];
}

export type Choice = {
    value: InputValue,
    label?: string,
};

export interface IModularFormPayload {
    formData?: IModularFormData;
    files?: IModularFormData;
    isValid?: boolean;
    createdAt?: Date;
}

export type FormPayLoadStorage = {
    [key: string]: IModularFormPayload;
};

export interface IFormFiles {
    [key: string]: FileList;
}

export type FileValue = {
    file: File;
    blob: string | ArrayBuffer; // base64
    compressedSize?: number;
    fileName?: string, // cannot access File in json format, so we save the filename here
};

export type Size = {
    width: number,
    height: number,
};

export type ImageCompressionOption = {
    enable: boolean,
    size?: Size,
};

export enum FileSizeUnitEnum {
    OCTET = 1,
    KILO = 1e+3,
    MEGA = 1e+6,
    GIGA = 1e+9,
    TERA = 1e+12,
}
