import {Component, Input, OnInit} from '@angular/core';
import {Indicateurs} from '../../../interfaces/Indicateurs';

@Component({
  selector: 'app-projet-caracteristiques',
  templateUrl: './projet-caracteristiques.component.html',
  styleUrls: ['./projet-caracteristiques.component.scss'],
})
export class ProjetCaracteristiquesComponent implements OnInit {
  @Input() indicateurs_projet: Indicateurs;
  @Input() indicateurs_offre: Indicateurs;

  constructor() { }

  ngOnInit() {}

}
