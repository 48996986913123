import {Component, Input, OnInit} from '@angular/core';
import {Indicateurs} from '../../../interfaces/Indicateurs';

@Component({
  selector: 'app-sujet-localisation-section',
  templateUrl: './sujet-localisation-section.component.html',
  styleUrls: ['./sujet-localisation-section.component.scss'],
})
export class SujetLocalisationSectionComponent implements OnInit {
  @Input() indicateurs_localisation: Indicateurs;

  constructor() { }

  ngOnInit() {}

}
