import {Component, OnInit} from '@angular/core';
import {StorageService} from '../../services/storage.service';

@Component({
    selector: 'app-detectnavigator',
    templateUrl: './detectnavigator.component.html',
    styleUrls: ['./detectnavigator.component.scss'],
})
export class DetectnavigatorComponent implements OnInit {
    private HAS_SEEN_POPUP_KEY = 'popup_was_shown';

    isChrome = navigator.userAgent.indexOf('Chrome') > -1;
    isClosed = false;
    hasSeenPopup = false;

    constructor(
        private storage: StorageService,
    ) {
        console.log('navigator.userAgent', navigator.userAgent);
    }

    ngOnInit() {
        this.hasSeenPopup = this.storage.get<boolean>(this.HAS_SEEN_POPUP_KEY);
    }

    closeWindows() {
        // Will show the popup only once per user/browser
        this.storage.set(this.HAS_SEEN_POPUP_KEY, 'true');
        this.isClosed = true;
    }

}
