import {Component, Input, OnInit} from '@angular/core';
import {Indicateurs, WithIndicateurs} from '../../../interfaces/Indicateurs';

@Component({
  selector: 'app-card-rows',
  templateUrl: './card-rows.component.html',
  styleUrls: ['./card-rows.component.scss'],
})
export class CardRowsComponent<T extends WithIndicateurs > implements OnInit {
  @Input() object: T;
  @Input() indicateurs: Indicateurs;

  constructor() { }

  ngOnInit() {}

}
