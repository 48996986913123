import {Injectable} from '@angular/core';

import { saveAs } from 'file-saver';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class PdfDownloadService {

    constructor(private http: HttpClient) {
    }

    downloadFile(api: string, fileName: string) {
        console.log({api, fileName});
        this.http.get(api, {responseType: 'blob'})
            .subscribe((file: Blob) => {
                console.log({file, fileName});
                saveAs(file, fileName ?? 'document.pdf');
            });
    }

}
