import {Component, Input, OnInit} from '@angular/core';
import {Indicateurs} from '../../../interfaces/Indicateurs';

@Component({
    selector: 'app-contact-data',
    templateUrl: './contact-data.component.html',
    styleUrls: ['./contact-data.component.scss'],
})
export class ContactDataComponent implements OnInit {
    @Input() imgSrc: string;
    @Input() title = 'contacts';
    @Input() indicateurs: Indicateurs;

    constructor() {
    }

    ngOnInit() {
    }

}
