import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Filter} from '../../../interfaces/Filter';

@Component({
    selector: 'app-filter-list',
    templateUrl: './filter-list.component.html',
    styleUrls: ['./filter-list.component.scss'],
})
export class FilterListComponent<T> implements OnInit {
    @Input() filters: Filter<T>[] = [];
    @Output() filtersChanged = new EventEmitter<void>();

    constructor() {
    }

    ngOnInit() {
    }

    onFilterChanged() {
        this.filtersChanged.emit();
    }

}
