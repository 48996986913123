import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageHeaderComponent} from '../components/page-header/page-header.component';
import {RouterModule} from '@angular/router';
import {MatModule} from './mat.module';
import {LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks} from 'ng-lazyload-image';
import {AutoCompleteModule} from 'ionic4-auto-complete';
import {CalltoactionComponent} from '../components/calltoaction/calltoaction.component';
import {SemainierComponent} from '../components/semainier/semainier.component';
import {IonicModule} from '@ionic/angular';
import {FriseComponent} from '../components/frise/frise.component';
import {BlurhashComponent} from '../blurhash/blurhash.component';
import {DeviceComponent} from '../components/device/device.component';
import {GrapheComponent} from '../components/graphe/graphe.component';
import {TranslateModule} from '@ngx-translate/core';
import {OuvertureevComponent} from '../components/ouvertureev/ouvertureev.component';
import {ElectrovanneComponent} from '../electrovanne/electrovanne.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FiltreinterventionPipe} from './../filtreintervention.pipe';
import {FiltreinterventionTypePipe} from './../filtreintervention-type.pipe';
import {FiltreinterventionUrgentPipe} from './../filtreintervention-urgent.pipe';
import {PhoneNumberFilterPipe} from '../phone-number-filter.pipe';
import {UserRoleKeyPipe} from '../user-role-key.pipe';
import {CreermissionPage} from '../interventions/interventionlist/creermission/creermission.page';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {ChoixinstallateurPage} from '../interventions/choixinstallateur/choixinstallateur.page';
import {StatusBadgeComponent} from '../components/atoms/status-badge/status-badge.component';
import { DetailinterventionPage } from '../interventions/detailintervention/detailintervention.page';
import {InterventionTypeBadgeComponent} from '../components/atoms/intervention-type-badge/intervention-type-badge.component';
import {InterventionRowComponent} from '../components/molecules/intervention-row/intervention-row.component';
import {MissionRowComponent} from '../components/molecules/mission-row/mission-row.component';
import {InterventionNavComponent} from '../components/organisms/intervention-nav/intervention-nav.component';
import { ConfirmationdialogComponent } from '../interventions/confirmationdialog/confirmationdialog.component';
import { SondesComponent } from '../components/sondes/sondes.component';
import {ResponsiveTabBarComponent} from '../components/molecules/responsive-tab-bar/responsive-tab-bar.component';
import {ProjectChronologyComponent} from '../components/molecules/project-chronology/project-chronology.component';
import { FilterPipe } from '../filter.pipe';
import {
    ProjectSubscriptionComponent
} from '../components/molecules/project-subscription/project-subscription.component';
import {
    InterventionRawListComponent
} from '../components/organisms/intervention-raw-list/intervention-raw-list.component';
import {FileListComponent} from '../components/molecules/file-list/file-list.component';
import {GalleryGroupComponent} from '../components/organisms/gallery-group/gallery-group.component';
import {NgImageFullscreenViewModule} from 'ng-image-fullscreen-view';
import {PhotosListComponent} from '../components/organisms/photos-list/photos-list.component';
import {PhotoCardComponent} from '../components/molecules/photo-card/photo-card.component';
import {RiskBadgeComponent} from '../components/atoms/risk-badge/risk-badge.component';
import {OffreGuardComponent} from '../components/atoms/offre-guard/offre-guard.component';
import {ModalHeaderComponent} from '../components/organisms/modal-header/modal-header.component';
import {LoadBtnComponent} from '../components/atoms/load-btn/load-btn.component';
import {MaintenanceConfirmDialogComponent} from '../components/dialogs/maintenance-confirm-dialog/maintenance-confirm-dialog.component';
import {LoaderComponent} from '../components/atoms/loader/loader.component';
import {InstallateurCardComponent} from '../components/molecules/installateur-card/installateur-card.component';
import { AffichagedendroComponent } from '../components/molecules/affichagedendro/affichagedendro.component';
import { AffichageSport6sComponent } from '../components/molecules/affichage-sport6s/affichage-sport6s.component';
import {CounterComponent} from '../components/atoms/counter/counter.component';
import {CounterListComponent} from '../components/molecules/counter-list/counter-list.component';
import {InterventionRemarqueComponent} from '../components/atoms/intervention-remarque/intervention-remarque.component';
import {
    InterventionCountersComponent
} from '../components/molecules/intervention-counters/intervention-counters.component';
import {CounterSimpleComponent} from '../components/atoms/counter-simple/counter-simple.component';
import {TimeDetailComponent} from '../components/atoms/time-detail/time-detail.component';
import {MissionEditorComponent} from '../components/organisms/mission-editor/mission-editor.component';
import {CollapsableComponent} from '../components/atoms/collapsable/collapsable.component';
import { SelectwithfilterComponent } from '../components/selectwithfilter/selectwithfilter.component';
import {
    SujetInstallationDiagFormComponent
} from '../components/organisms/sujet-installation-diag-form/sujet-installation-diag-form.component';
import {DataRowComponent} from '../components/atoms/data-row/data-row.component';

import {TableInputComponent} from '../components/atoms/table-input/table-input.component';
import {ThemeFetcherComponent} from '../components/atoms/theme-fetcher/theme-fetcher.component';
import {ProjectInfoCardComponent} from '../components/molecules/project-info-card/project-info-card.component';
import {SujetCardComponent} from '../components/molecules/sujet-card/sujet-card.component';
import {CardRowComponent} from '../components/atoms/card-row/card-row.component';
import {ButtonComponent} from '../components/atoms/button/button.component';
import {RadioButtonGroupComponent} from '../components/molecules/radio-button-group/radio-button-group.component';
import { CircularPhotoScrollComponent } from '../components/circular-photo-scroll/circular-photo-scroll.component';
import {FilterListComponent} from '../components/molecules/filter-list/filter-list.component';
import {FilterListToggleComponent} from "../components/molecules/filter-list-toggle/filter-list-toggle.component";
import {CardRowsComponent} from "../components/molecules/card-rows/card-rows.component";
import {KeyvalComponent} from "../components/atoms/keyval/keyval.component";
import {AnchorLayoutComponent} from "../components/templates/anchor-layout/anchor-layout.component";
import {ScrollToDirective} from "../directives/scroll-to.directive";
import {
    SujetDetailsSectionComponent
} from "../components/organisms/sujet-details-section/sujet-details-section.component";
import {
    SujetDispositifSectionComponent
} from "../components/organisms/sujet-dispositif-section/sujet-dispositif-section.component";
import {
    SujetGalerieSectionComponent
} from "../components/organisms/sujet-galerie-section/sujet-galerie-section.component";
import {ModularSectionComponent} from "../components/templates/modular-section/modular-section.component";
import {
    SujetLocalisationSectionComponent
} from "../components/organisms/sujet-localisation-section/sujet-localisation-section.component";
import {
    SujetDernierBulletinSectionComponent
} from "../components/organisms/sujet-dernier-bulletin-section/sujet-dernier-bulletin-section.component";
import {SujetStadeSectionComponent} from "../components/organisms/sujet-stade-section/sujet-stade-section.component";
import {SujetNotesSectionComponent} from "../components/organisms/sujet-notes-section/sujet-notes-section.component";
import {SujetHelpSectionComponent} from "../components/organisms/sujet-help-section/sujet-help-section.component";
import {BulletinsGroupComponent} from "../components/organisms/bulletins-group/bulletins-group.component";
import {BulletinCardComponent} from "../components/organisms/bulletin-card/bulletin-card.component";
import {LanguageSelectorComponent} from "../components/molecules/language-selector/language-selector.component";
import {ThemeTogglerComponent} from "../components/molecules/theme-toggler/theme-toggler.component";
import {NewsletterFormComponent} from "../components/molecules/newsletter-form/newsletter-form.component";
import {SettingsSectionComponent} from "../components/organisms/settings-section/settings-section.component";
import {BreadcrumbComponent} from "../components/molecules/breadcrumb/breadcrumb.component";
import {ProjetSettingsComponent} from "../components/organisms/projet-settings/projet-settings.component";
import {BreadcrumbItemComponent} from "../components/atoms/breadcrumb-item/breadcrumb-item.component";
import {
    ProjetDocumentsSectionComponent
} from "../components/organisms/projet-documents-section/projet-documents-section.component";
import {
    ProjetGroupeSectionComponent
} from "../components/organisms/projet-groupe-section/projet-groupe-section.component";
import {
    ProjetContactsSectionComponent
} from "../components/organisms/projet-contacts-section/projet-contacts-section.component";
import {DocumentItemComponent} from "../components/molecules/document-item/document-item.component";
import {PersonCardComponent} from "../components/molecules/person-card/person-card.component";
import {ContactDataComponent} from "../components/molecules/contact-data/contact-data.component";
import {FigureComponent} from "../components/atoms/figure/figure.component";
import {ContactsAllComponent} from "../components/organisms/contacts-all/contacts-all.component";
import {LegendComponent} from "../components/organisms/legend/legend.component";
import {DefinitionListComponent} from "../components/molecules/definition-list/definition-list.component";
import {FaqSectionComponent} from "../components/organisms/faq-section/faq-section.component";
import { OfflineBannerComponent } from '../components/offline-banner/offline-banner.component';
import {KeyvalsComponent} from "../components/molecules/keyvals/keyvals.component";
import {
    ProjetCaracteristiquesComponent
} from "../components/organisms/projet-caracteristiques/projet-caracteristiques.component";
import {FileFormComponent} from "../components/organisms/file-form/file-form.component";
import {InputSimpleComponent} from "../components/molecules/input-simple/input-simple.component";
import {FileInputSimpleComponent} from "../components/molecules/file-input-simple/file-input-simple.component";
import {
    SujetGalerieCarouselComponent
} from "../components/organisms/sujet-galerie-carousel/sujet-galerie-carousel.component";


@NgModule({
    declarations: [
        AffichagedendroComponent,
        SelectwithfilterComponent,
        BlurhashComponent,
        CalltoactionComponent,
        ChoixinstallateurPage,
        ConfirmationdialogComponent,
        CounterComponent,
        CounterListComponent,
        CreermissionPage,
        DetailinterventionPage,
        DeviceComponent,
        ElectrovanneComponent,
        FileListComponent,
        FiltreinterventionPipe,
        FiltreinterventionTypePipe,
        FiltreinterventionUrgentPipe,
        PhoneNumberFilterPipe,
        CalltoactionComponent,
        ElectrovanneComponent,
        SemainierComponent,
        PageHeaderComponent,
        FriseComponent,
        GalleryGroupComponent,
        GrapheComponent,
        InstallateurCardComponent,
        InterventionNavComponent,
        InterventionRawListComponent,
        InterventionRowComponent,
        InterventionTypeBadgeComponent,
        LoadBtnComponent,
        LoaderComponent,
        MaintenanceConfirmDialogComponent,
        MissionRowComponent,
        ModalHeaderComponent,
        OffreGuardComponent,
        OuvertureevComponent,
        PageHeaderComponent,
        PhotoCardComponent,
        PhotosListComponent,
        ProjectChronologyComponent,
        ProjectSubscriptionComponent,
        ResponsiveTabBarComponent,
        RiskBadgeComponent,
        SemainierComponent,
        SondesComponent,
        StatusBadgeComponent,
        UserRoleKeyPipe,
        FilterPipe,
        OffreGuardComponent,
        ModalHeaderComponent,
        AffichagedendroComponent,
        AffichageSport6sComponent,
        LoadBtnComponent,
        MaintenanceConfirmDialogComponent,
        LoaderComponent,
        InstallateurCardComponent,
        InterventionRemarqueComponent,
        SemainierComponent,
        SondesComponent,
        StatusBadgeComponent,
        UserRoleKeyPipe,
        InterventionCountersComponent,
        CounterSimpleComponent,
        TimeDetailComponent,
        MissionEditorComponent,
        CollapsableComponent,
        DataRowComponent,
        ThemeFetcherComponent,
        ProjectInfoCardComponent,
        SujetCardComponent,
        CardRowComponent,
        ButtonComponent,
        RadioButtonGroupComponent,
        CircularPhotoScrollComponent,
        FilterListComponent,
        FilterListToggleComponent,
        CardRowsComponent,
        KeyvalComponent,
        AnchorLayoutComponent,
        ScrollToDirective,
        SujetDetailsSectionComponent,
        SujetDispositifSectionComponent,
        SujetGalerieSectionComponent,
        ModularSectionComponent,
        SujetLocalisationSectionComponent,
        SujetDernierBulletinSectionComponent,
        SujetStadeSectionComponent,
        SujetNotesSectionComponent,
        SujetHelpSectionComponent,
        BulletinsGroupComponent,
        BulletinCardComponent,
        LanguageSelectorComponent,
        ThemeTogglerComponent,
        NewsletterFormComponent,
        SettingsSectionComponent,
        BreadcrumbComponent,
        ProjetSettingsComponent,
        BreadcrumbItemComponent,
        ProjetDocumentsSectionComponent,
        ProjetGroupeSectionComponent,
        ProjetContactsSectionComponent,
        DocumentItemComponent,
        PersonCardComponent,
        ContactDataComponent,
        FigureComponent,
        ContactsAllComponent,
        LegendComponent,
        DefinitionListComponent,
        FaqSectionComponent,
        OfflineBannerComponent,
        KeyvalsComponent,
        ProjetCaracteristiquesComponent,
        FileFormComponent,
        InputSimpleComponent,
        FileInputSimpleComponent,
        SujetGalerieCarouselComponent,
    ],
    imports: [
        AutoCompleteModule,
        CommonModule,
        FormsModule,
        InfiniteScrollModule,
        IonicModule,
        LazyLoadImageModule,
        LeafletModule,
        MatModule,
        NgImageFullscreenViewModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
    ],
    providers: [{provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks}],
    exports: [
        AffichagedendroComponent,
        SelectwithfilterComponent,
        AffichageSport6sComponent,
        AutoCompleteModule,
        BlurhashComponent,
        CalltoactionComponent,
        ChoixinstallateurPage,
        ConfirmationdialogComponent,
        CounterComponent,
        CounterListComponent,
        CreermissionPage,
        DetailinterventionPage,
        DeviceComponent,
        ElectrovanneComponent,
        FiltreinterventionPipe,
        FiltreinterventionTypePipe,
        FiltreinterventionUrgentPipe,
        FilterPipe,
        PhoneNumberFilterPipe,
        FormsModule,
        FriseComponent,
        GalleryGroupComponent,
        GrapheComponent,
        InfiniteScrollModule,
        InterventionNavComponent,
        InterventionRawListComponent,
        InterventionRowComponent,
        InterventionTypeBadgeComponent,
        LazyLoadImageModule,
        MatModule,
        MissionRowComponent,
        ModalHeaderComponent,
        OffreGuardComponent,
        OuvertureevComponent,
        PageHeaderComponent,
        PhotoCardComponent,
        PhotosListComponent,
        ProjectChronologyComponent,
        ProjectSubscriptionComponent,
        ReactiveFormsModule,
        ResponsiveTabBarComponent,
        RiskBadgeComponent,
        SemainierComponent,
        SondesComponent,
        StatusBadgeComponent,
        TranslateModule,
        UserRoleKeyPipe,
        InterventionCountersComponent,
        CounterSimpleComponent,
        TimeDetailComponent,
        InterventionRemarqueComponent,
        InstallateurCardComponent,
        MissionEditorComponent,
        CollapsableComponent,
        DataRowComponent,
        LoaderComponent,
        ThemeFetcherComponent,
        ProjectInfoCardComponent,
        SujetCardComponent,
        ButtonComponent,
        RadioButtonGroupComponent,
        CardRowComponent,
        CircularPhotoScrollComponent,
        FilterListComponent,
        FilterListToggleComponent,
        CardRowsComponent,
        KeyvalComponent,
        AnchorLayoutComponent,
        SujetDetailsSectionComponent,
        SujetDispositifSectionComponent,
        SujetGalerieSectionComponent,
        ModularSectionComponent,
        SujetLocalisationSectionComponent,
        SujetDernierBulletinSectionComponent,
        SujetStadeSectionComponent,
        SujetNotesSectionComponent,
        SujetHelpSectionComponent,
        BulletinsGroupComponent,
        LanguageSelectorComponent,
        ThemeTogglerComponent,
        NewsletterFormComponent,
        SettingsSectionComponent,
        BreadcrumbComponent,
        ProjetSettingsComponent,
        ProjetDocumentsSectionComponent,
        ProjetGroupeSectionComponent,
        ProjetContactsSectionComponent,
        ContactDataComponent,
        ContactsAllComponent,
        LegendComponent,
        FaqSectionComponent,
        OfflineBannerComponent,
        ProjetCaracteristiquesComponent,
    ]
})
export class SharedModule {
}
