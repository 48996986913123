import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projet-settings',
  templateUrl: './projet-settings.component.html',
  styleUrls: ['./projet-settings.component.scss'],
})
export class ProjetSettingsComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
