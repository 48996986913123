import {Component, Input, OnInit} from '@angular/core';

export type FaqItem = {
    question: string;
    answer: string;
};

@Component({
    selector: 'app-faq-section',
    templateUrl: './faq-section.component.html',
    styleUrls: ['./faq-section.component.scss'],
})
export class FaqSectionComponent implements OnInit {
    @Input() title = 'FAQ';
    @Input() questions: FaqItem[] = [
        {
            question: 'Question 1',
            answer: 'Reponse 1'
        },
        {
            question: 'Question 2',
            answer: 'Reponse 2'
        },
        {
            question: 'Question 3',
            answer: 'Reponse 3'
        },
    ];

    constructor() {
    }

    ngOnInit() {
    }

}
