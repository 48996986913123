import {Component, Input, OnInit} from '@angular/core';
import {Sujet} from '../../../interfaces/ISujet';
import {Indicateurs} from "../../../interfaces/Indicateurs";

@Component({
  selector: 'app-sujet-dispositif-section',
  templateUrl: './sujet-dispositif-section.component.html',
  styleUrls: ['./sujet-dispositif-section.component.scss'],
})
export class SujetDispositifSectionComponent implements OnInit {
  @Input() subject: Sujet;
  @Input() autre_sondes: Indicateurs;
  @Input() indicateur_sondes: Indicateurs;

  constructor() { }

  ngOnInit() {}

}
