import {Component, Input, OnInit} from '@angular/core';
import {DisplayService} from '../../services/display.service';
import {UtilsService} from '../../services/utils.service';
import {ModalController} from '@ionic/angular';
import {DetailSujetPage} from '../../pages/detail-sujet/detail-sujet.page'
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-device',
    templateUrl: './device.component.html',
    styleUrls: ['./device.component.scss'],
})
export class DeviceComponent implements OnInit {
    @Input() device: any;
    id_projet: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public display: DisplayService,
        public utils: UtilsService,
        private modalController: ModalController
    ) {
        this.id_projet = this.route.snapshot.params.id_projet;

    }

    ngOnInit() {
        this.device = this.utils.analyseSonde(this.device);
    }

    public async presentModalSujet(programmation) {

        const modal = await this.modalController.create({
            component: DetailSujetPage,
            cssClass: 'my-custom-class',
            componentProps: {
                'id_sujet': programmation
            }
        });
        modal.onDidDismiss().then(data => {

            if (data.data.update) {
                if (data.data.update == true) {


                }
            }
        });
        return await modal.present();
    }

    goSujet(vegetalid) {
        this.router.navigate(['/site/' + this.id_projet + '/equipement/sujet', vegetalid]);
    }

    getVbatColor(device) {
        let color = "red";
        if (device) {
            if (device?.trames) {
                if (device?.trames[0]) {
                    if (device?.trames[0].messages) {
                        if (device?.trames[0].messages?.vbat_mv) {
                            if (device?.trames[0].messages?.vbat_mv < 5000) {

                            } else if (device?.trames[0].messages?.vbat_mv >= 5000 && device?.trames[0].messages?.vbat_mv < 5500) {
                                color = "orange";
                            } else if (device?.trames[0].messages?.vbat_mv >= 5500) {
                                color = "#55c277";
                            }

                        }

                    }
                }

            }

        }
        return color;
    }

}
