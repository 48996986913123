import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Choice} from '../../../interfaces/IFormStep';

@Component({
    selector: 'app-radio-button-group',
    templateUrl: './radio-button-group.component.html',
    styleUrls: ['./radio-button-group.component.scss'],
})
export class RadioButtonGroupComponent implements OnInit {
    @Input() choices: Choice[] = [];
    @Input() selectedValue: string | null = null;
    @Output() selectionChange = new EventEmitter<any>();


    onSelectValue(value: string) {
        this.selectedValue = value;
        console.log('just selected', value);
        console.log(value);
        this.selectionChange.emit(value);
    }

    constructor() {
    }

    ngOnInit() {
    }


}
