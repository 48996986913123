import {Component, Input, OnInit} from '@angular/core';
import {BreadcrumbItem} from '../../molecules/breadcrumb/breadcrumb.component';

@Component({
    selector: 'app-breadcrumb-item',
    templateUrl: './breadcrumb-item.component.html',
    styleUrls: ['./breadcrumb-item.component.scss'],
})
export class BreadcrumbItemComponent {
    @Input() item: BreadcrumbItem;
}
