import {Component, Input, OnInit} from '@angular/core';
import {ProjectInfo} from '../../../interfaces/IProjet';
import {UtilsService} from '../../../services/utils.service';

@Component({
    selector: 'app-project-info-card',
    templateUrl: './project-info-card.component.html',
    styleUrls: ['./project-info-card.component.scss'],
})
export class ProjectInfoCardComponent implements OnInit {
    @Input() project: ProjectInfo;

    constructor(
        public utils: UtilsService, // TODO remove
    ) {
    }

    ngOnInit() {
    }

}
