import {Component, OnInit} from '@angular/core';
import {DefinitionListItem} from "../../molecules/definition-list/definition-list.component";

@Component({
    selector: 'app-legend',
    templateUrl: './legend.component.html',
    styleUrls: ['./legend.component.scss'],
})
export class LegendComponent implements OnInit {
    colorDefinitions: DefinitionListItem[] = [
        {
            icon: 'ellipse',
            color: 'success',
            definition: 'Une icone en vert indique que tout va bien'
        },
        {
            icon: 'ellipse',
            color: 'warning',
            definition: 'Une icône en orange mérite votre attention'
        },
        {
            icon: 'ellipse',
            color: 'danger',
            definition: 'Une icône en rouge attend une action de votre part'
        },
    ];

    iconsDefinitions: DefinitionListItem[] = [
        {
            icon: 'water',
            color: 'success',
            definition: 'Sujet sain'
        },
        {
            icon: 'water',
            color: 'warning',
            definition: 'Sujet en difficulté, mérite une attention de votre part',
        },
        {
            icon: 'water',
            color: 'danger',
            definition: 'Sujet en alerte, mérite une action urgente de votre part',
        },
        {
            icon: 'water',
            color: 'secondary',
            definition: 'Sujet saturé en eau, une vigilance rdt requise pour éviter un pourrissement',
        },
        {
            icon: 'wifi',
            color: 'success',
            definition: 'Sonde en bon fonctionnement',
        },
        {
            icon: 'wifi',
            color: 'danger',
            definition: 'Sonde défectueuse, mérite une réparation ou un signalement',
        },
    ];

    constructor() {
    }

    ngOnInit() {
    }

}
