import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sujet-help-section',
  templateUrl: './sujet-help-section.component.html',
  styleUrls: ['./sujet-help-section.component.scss'],
})
export class SujetHelpSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
