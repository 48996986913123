import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenericLoaderService {

  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  private requestStack: string[] = [];  // Pile pour stocker les URLs des requêtes

  show(url: string) {
    if (!this.requestStack.includes(url)) {
      this.requestStack.push(url);  // Ajoute l'URL de la requête à la pile
      console.log(`Request started for: ${url}. Stack length: ${this.requestStack.length}`);
      this.loadingSubject.next(true);  // Affiche le loader
    } else {
      console.warn(`Duplicate request detected for: ${url}`);
    }
  }

  hide() {
    if (this.requestStack.length > 0) {
      const completedRequest = this.requestStack.pop();  // Retire l'URL de la pile
      console.log(`Request ended for: ${completedRequest}. Stack length: ${this.requestStack.length}`);
    } else {
      console.warn('Attempted to hide loader, but requestStack is already empty!');
    }

    if (this.requestStack.length === 0) {
      console.log('Request stack is empty, hiding loader');
      this.loadingSubject.next(false);  // Cache le loader seulement si la pile est vide
    }
  }
}